import React from "react";
import { BsGithub, BsInstagram, BsLinkedin } from "react-icons/bs";

const SocialMedia = () => (
  <div className='app__social'>
    <div>
      <a href='https://github.com/AkhilSuresh-2000'>
        <BsGithub />
      </a>
    </div>
    <div>
      <a href='https://www.linkedin.com/in/akhil-suresh-1052411b6/'>
        <BsLinkedin />
      </a>
    </div>
    <div>
      <a href='https://www.instagram.com/akhilsuresh46/'>
        <BsInstagram />
      </a>
    </div>
  </div>
);

export default SocialMedia;
